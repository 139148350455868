import React, { useState } from "react"
import "../styles/global.css"
import "../styles/variables.css"
import "animate.css/animate.css"
import "bootstrap/dist/css/bootstrap.css"
import "@fortawesome/fontawesome-free/css/all.css"
import styled from "styled-components"
import Hero from "./hero/hero"
import Footer from "./footer/footer"
import { MobileMenu } from "./navigation/mobileMenu"
import Navigation from "./navigation/navigation.component"
import mq from "../styles/media-query"
import { CrispChatHelper } from "./chat/crisp-chat-helper"

const PageSlideContainer = styled.div`
    overflow-x: hidden;
`

const PageSlider = styled.div`
    transition: all 0.25s ease-out;
    position: relative;
    z-index: 3;
    transform: ${props =>
            props.mobileMenuOpen ? "translate(-200px, 0)" : "none"};

    header + .container {
        min-height: calc(100vh - 379px);
    }

    *:not(.container) + .headline {
        margin-top: 2.4rem;
        ${mq.md`
            margin-top: 4.8rem;
        `}
    }

    .row > * + * {
        margin-top: 2rem;
        margin-bottom: 2rem;

        ${mq.md`
            margin-top: 0;
            margin-bottom: 0;
        `}
    }

    ul[tinydots] {
        li {
            margin-left: 1.6rem;
            position: relative;

            &::before {
                position: absolute;
                left: -1rem;
                top: 0;
                display: inline-block;
                content: "\\00B7";
            }
        }

        li + li {
            margin-top: 1rem;
        }
    }
`

const MobileMenuContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    width: 198px;
    height: 100vh;
`

export const Headline = styled.div`
    border-bottom: 1px solid var(--brandPrimary);
    padding: 2rem 0 0.5rem;
    margin-bottom: 2.4rem;
    color: var(--brandSecondary);
    margin-top: 0rem;
    font-size: 22px;

    ${mq.sm`
        font-size: 32px;
        margin-top: 2.4rem;
    `}
    ${mq.md`
        font-size: 33px;
        margin-top: 2.4rem;
    `}
    ${mq.lg`
        font-size: 36px;
    `}
    h1, h2, h3, h4, h5 {
        margin: 0;
        padding: 0;
    }
`

export const StyledCarousel = styled.div`
    margin-top: 0px;

    ${mq.md`
        margin-top: 48px;
    `}
    .carousel {
        padding: 0 40px;
    }

    .carousel-indicators {
        display: none;
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 60px;
        opacity: 1;
    }

    .carousel-control-prev {
        left: -20px;
    }

    .carousel-control-next {
        right: -20px;
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%239aa9c6' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%239aa9c6' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
`

export const BigList = styled.ul`
    font-size: 125%;

    &[data-cols="true"] {
        ${mq.md`
        columns: 2;
        `}
    }

    li {
        display: flex;
        align-items: center;
        line-height: 1;
    }

    i {
        font-size: 125%;
        margin-right: 0.8rem;
        color: var(--brandPrimary)
    }

    span {
        padding-top: 0.2rem;
    }
`

export const ThreeUp = styled.div`
    padding-bottom: 2rem;
    text-align: center;
    // text-transform: uppercase;

    &.row > div {
        margin-bottom: 0;
        margin-top: 2rem;
    }

    ${mq.md`
        padding-bottom: 6rem;
    `}
    img {
        display: block;
        margin-bottom: 0.8rem;
    }
`

export const SubIMG = styled.img`
    max-width: 100%;
    height: auto;
    object-fit: contain;
`

export default function Layout(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <PageSlideContainer>
      <PageSlider mobileMenuOpen={mobileMenuOpen}>
        <Navigation
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
        {props.hero !== false && (
          <Hero
            bg={props.bg}
            header={props.header}
            subheader={props.subheader}
          />
        )}

        {props.children}
        <CrispChatHelper />
        <Footer />
      </PageSlider>
      <MobileMenuContainer mobileMenuOpen={mobileMenuOpen}>
        <MobileMenu menuOpen={mobileMenuOpen} />
      </MobileMenuContainer>
    </PageSlideContainer>
  )
}
