import React, { useCallback, useEffect } from "react"

export const CrispChatHelper = ({ children }) => {
  const [initialized, setInitialized] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [phoneNumber, setPhoneNumber] = React.useState("")
  const [name, setName] = React.useState("")
  const [$crisp, set$crisp] = React.useState(null)



  const initialize = useCallback(() => {
    if (initialized) {
      return
    }
    setInitialized(true)
    set$crisp(window["$crisp"])

  }, [initialized])

  useEffect(() => {
    if (!$crisp) return
    $crisp.push(["on", "chat:opened", function() {

      const existingEmail = $crisp.get("user:email");
      const existingPhone = $crisp.get("user:phone");
      const existingName = $crisp.get("user:nickname");
      if (!existingEmail) {
        $crisp.push(["do", "message:show", ["field", {
          "id": "field-email",
          "text": "What is your email?",
          "explain": "Enter your email..."
        }]])
      }
      else if (!existingPhone) {
        $crisp.push(["do", "message:show", ["field", {
          "id": "field-phone",
          "text": "What is your email?",
          "explain": "Enter your email..."
        }]])
      } else if (!existingName) {
        $crisp.push(["do", "message:show", ["field", {
          "id": "field-name",
          "text": "What is your name?",
          "explain": "Enter your name..."
        }]])
      } else {
        $crisp.push(["do", "message:show", ["text", `Welcome back, ${existingName}! How can we help you today?`]])
      }
    }])

    $crisp.push(["on", "message:received", function(message) {
      const id = message && message.content && message.content.id
      if (message.type === "field") {
        switch (id) {
          case "field-email":
            setEmail(message.content.value)
            break
          case "field-phone":
            setPhoneNumber(message.content.value)
            break
          case "field-name":
            setName(message.content.value)
            break
          default:
            break
        }
      }
    }])

  }, [$crisp, setEmail, setPhoneNumber, setName])

  useEffect(() => {
    if (!email) return
    $crisp.push(["set", "user:email", [email]])
    $crisp.push(["do", "message:send", ["text", email]])
    $crisp.push(["do", "message:show", ["field", {
      "id": "field-phone",
      "text": "What is your phone number?",
      "explain": "Enter your phone number..."
    }]])

  }, [$crisp, email])

  useEffect(() => {
    if (!phoneNumber) return
    $crisp.push(["set", "user:phone", [phoneNumber]])
    $crisp.push(["do", "message:send", ["text", phoneNumber]])
    $crisp.push(["do", "message:show", ["field", {
      "id": "field-name",
      "text": "What is your name?",
      "explain": "Enter your name..."
    }]])
  }, [$crisp, phoneNumber])

  useEffect(() => {
    if (!name) return
    $crisp.push(["set", "user:nickname", [name]])
    $crisp.push(["do", "message:send", ["text", name]])
    $crisp.push(["do", "message:show", ["text", `Hello ${name}! How can we help you today?`]])
  }, [$crisp, name])

  useEffect(() => {
    initialize()
  }, [initialize])
  return React.Fragment
}